<template>
  <div class="case">
    <b-img
      :src="require('../../assets/case_banner.jpg')"
      fluid-grow
      alt="Responsive image"
    ></b-img>
    <div class="problem_box">
      <b-container class="bv-example-row" fluid>
        <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="1">
          <b-col class="mb-4 wow fadeInUp" style="text-align: center"
            ><b-card tag="article" class="mb-2" style="border: 0">
              <b-card-title style="text-align: center" class="mb-4 mt-2"
                >精准加药控制系统</b-card-title
              >
              <!-- <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/about/xk_m.jpg')"
                fluid
              ></b-img> -->
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >某造纸厂高级氧化处理系统</b-card-sub-title
              >
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
                •
               小鲲智能算法团队通过采集现场数据并与运维工程师、行业专家的深度沟通，梳理清晰该场景的工艺流程与需求，建立预测模型。
              </b-card-text>
              <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/about/xk_p3.png')"
                fluid
              ></b-img>
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
                ①
               数据采集环节，通过流量数据、cod在线、PH，MLSS、色度、水温等关键参数，配合小鲲自研的视觉絮凝效果在线检测设备，辅以人工cod化验数据，构建包含历史数据与实时数据的投药参数数据库。
              </b-card-text>
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
                ②
               通过数据探索性分析完成数据理解，从数据的角度去解读业务。历经数据筛选、缺失值处理、异常处理、数据分析等环节后，为模型输出可用的数据源。
              </b-card-text>
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
                ③
               算法工程师融合机理知识、专家经验，选择合适的算法和建模工具，建立基于深度学习的高精度、有效可靠的仿真模型。
              </b-card-text>
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
                ④
               在仿真模型的基础上添加强化学习模型（将水力停留时间、溶解氧、去除率等变量变化过程建模），通过AI模糊学习的能力，为参数自动优化提供支持。
              </b-card-text>
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
               ⑤
               通过对上述仿真与优化整体模型的多轮测试、评估与迭代优化后，实现在水质约束条件下的加药最优解方案。
              </b-card-text>
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
               ⑥
               通过IOT下发加药指令给加药装置完成精准加药。
              </b-card-text>
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >算法接入与人工对比</b-card-sub-title
              >
              <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/about/xk_p.jpg')"
                fluid
              ></b-img>
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >项目收益</b-card-sub-title
              >
              <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/about/xk_p2.jpg')"
                fluid
              ></b-img> </b-card
          ></b-col>
        </b-row>
      </b-container>
    </div>
    <!-- <Footers /> -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import Footers from "../../components/footers.vue";
export default {
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "精准加药控制系统",
      meta: [
        {
          name: "keywords",
          content: "小鲲智能,小鲲智能技术(广州)有限公司,小鲲智能技术广州有限公司,精准加药,精准加药控制系统",
        },
        {
          name: "description",
          content:
            "小鲲智能算法团队通过采集现场数据并与运维工程师、行业专家的深度沟通，梳理清晰该场景的工艺流程与需求，建立预测模型。",
        },
      ],
    };
  },
  created() {
    scrollTo(0, 0);
  },
  // components: {
  //   Footers,
  // },
  mounted() {
    new WOW({ live: false }).init();
  },
};
</script>
<style lang="less" scoped>
.case {
  .problem_box {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 5px #f0f0f0 inset;
    padding: 40px 320px 20px 320px;
    .text-muted {
      color: #0099ba !important;
    }
  }
  @media screen and (min-width: 375px) and (max-width: 992px) {
    .problem_box {
      padding: 40px 20px 40px 20px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1550px) {
    .problem_box {
      padding: 40px 140px 40px 140px;
    }
  }
  @media screen and (max-width: 375px) {
    .problem_box {
      padding: 40px 0 40px 0;
    }
  }
}
</style>