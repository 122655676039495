<template>
  <div class="case">
    <b-img
      :src="require('../../assets/product/index3.jpg')"
      fluid-grow
      alt="Responsive image"
    ></b-img>
    <div class="problem_box">
      <b-container class="bv-example-row" fluid>
        <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="1">
          <b-col class="mb-4 wow fadeInUp">
            <b-card tag="article" class="mb-2" style="border: 0">
              <b-card-title style="text-align: center" class="mb-4 mt-2"
                >与软件配套的自研硬件产品</b-card-title
              >
              <!-- <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/about/xk_m.jpg')"
                fluid
              ></b-img> -->
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >智能物联PLC</b-card-sub-title
              >
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                •
                环保智慧化，是多学科多专业混合跨界的系统，为了适配软件系统的远程控制功能，小鲲结合目前工业现场的各种控制需求，紧扣环保刚需，硬件技术团队工程师历时多年精心打磨，研发出车规级的智能物联PLC产品，快人一步实现物联。
              </b-card-text>
              <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/product/plc_home.png')"
                fluid
              ></b-img>
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >产品特点</b-card-sub-title
              >
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ①
                全金属外壳，IP65防护等级，环境耐受温度-40°— 85°C；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ②
                车规级快插接口，安装调试方便、连接可靠；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ③
                集成4G通讯，实现数据与云服务器的双向无线传输，不受地理区域限制；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ④
                丰富的数据采集控制接口（数字量输入/输出、模拟量输入/输出 (4-20mA)、RS485  (Modbus-RTU)；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ⑤
                高性能ARM处理芯片，可实现现场的各种自控逻辑需求；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ⑥
                电容触摸屏人机接口，可应对现场操控与调试；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ⑦
                完全的电气隔离，让系统更安全；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ⑧
                完善的故障诊断技术，实现设备故障的精准定位；
              </b-card-text>
              <b-card-text style="text-align: left; line-height: 50px; text-indent: 2em">
                ⑨
                远程OTA，远程灵活添加功能和软件系统BUG修复，维护更方便；
              </b-card-text>
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >产品系列</b-card-sub-title
              >
            </b-card>
          </b-col>
        </b-row>
        <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="1">
          <b-col class="mb-4 wow fadeInUp">
            <b-card no-body class="overflow-hidden">
              <b-row no-gutters>
                <b-col md="5">
                  <b-card-img
                    :src="require('../../assets/product/PLC-1.png')"
                    alt="Image"
                    class="rounded-0"
                    height="100%"
                  ></b-card-img>
                </b-col>
                <b-col md="7">
                  <b-card-body title="KST-512C智能物联PLC">
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 2路RS485隔离接口
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 36路光耦隔离数字输入
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 24路晶体管数字输出
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 12路4-20mA模拟量隔离输入
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 2路4-20mA模拟量隔离输出
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="mb-4 wow fadeInUp">
            <b-card no-body class="overflow-hidden">
              <b-row no-gutters>
                <b-col md="5">
                  <b-card-img
                    :src="require('../../assets/product/PLC-2.png')"
                    alt="Image"
                    class="rounded-0"
                    height="100%"
                  ></b-card-img>
                </b-col>
                <b-col md="7">
                  <b-card-body title="KST-312C智能物联PLC">
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 2路RS485隔离接口
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 40路光耦隔离数字输入
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 28路晶体管数字输出
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 4路4-20mA模拟量隔离输入
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 2路4-20mA模拟量隔离输出
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="mb-4 wow fadeInUp">
            <b-card no-body class="overflow-hidden">
              <b-row no-gutters>
                <b-col md="5">
                  <b-card-img
                    :src="require('../../assets/product/PLC-3.png')"
                    alt="Image"
                    class="rounded-0"
                    height="100%"
                  ></b-card-img>
                </b-col>
                <b-col md="7">
                  <b-card-body title="KST-011C智能物联PLC">
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 1路RS485隔离接口
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 24路光耦隔离数字输入
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 12路晶体管数字输出
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 4路4-20mA模拟量隔离输入
                    </b-card-text>
                    <b-card-text
                      class="wow fadeInUp"
                      style="text-align: left; text-indent: 1em; line-height: 50px"
                    >
                      • 2路4-20mA模拟量隔离输出
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="1">
          
        </b-row>
        <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="1">
          
        </b-row> -->
      </b-container>
    </div>
    <!-- <Footers /> -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import Footers from "../../components/footers.vue";
export default {
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "智能物联PLC",
      meta: [
        {
          name: "keywords",
          content:
            "小鲲智能,小鲲智能技术(广州)有限公司,小鲲智能技术广州有限公司,智能物联PLC",
        },
        {
          name: "description",
          content:
            "小鲲自研的物联PLC或网关为前端感知入口，通过小鲲AIoT平台实现设备的远程控制、实时参数读取、设备故障报警及运维人员管理，实现无人化精准运维。",
        },
      ],
    };
  },
  created() {
    scrollTo(0, 0);
  },
  // components: {
  //   Footers,
  // },
  mounted() {
    new WOW({ live: false }).init();
  },
};
</script>
<style lang="less" scoped>
.case {
  .problem_box {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px #ccc inset;
    padding: 50px 150px 20px 150px;
    .text-muted {
      color: #0099ba !important;
    }
  }
  @media screen and (min-width: 375px) and (max-width: 992px) {
    .problem_box {
      padding: 50px 20px 40px 20px;
      .card-body {
        .card-title {
          font-size: 18px;
        }
        .card-text {
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1550px) {
    .problem_box {
      padding: 50px 50px 40px 50px;
      .card-body {
        .card-title {
          font-size: 18px;
        }
        .card-text {
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    .problem_box {
      padding: 50px 0 40px 0;
      .card-body {
        .card-title {
          font-size: 18px;
        }
        .card-text {
          font-size: 14px;
        }
      }
    }
  }
  .rounded-0 {
    object-fit: cover;
  }
}
</style>
