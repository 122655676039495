<template>
  <div class="case">
    <b-img
      :src="require('../../assets/case_banner.jpg')"
      fluid-grow
      alt="Responsive image"
    ></b-img>
    <div class="problem_box">
      <b-container class="bv-example-row" fluid>
        <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="1">
          <b-col class="mb-4 wow fadeInUp" style="text-align: center"
            ><b-card tag="article" class="mb-2" style="border: 0">
              <b-card-title style="text-align: center" class="mb-4 mt-2"
                >设备运维管理系统</b-card-title
              >
              <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/about/xk_m.jpg')"
                fluid
              ></b-img>
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >小鲲智能解决方案与实践</b-card-sub-title
              >
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
                •
                以小鲲自研的物联PLC或网关为前端感知入口，通过小鲲AIoT平台实现设备的远程控制、实时参数读取、设备故障报警及运维人员管理，实现无人化精准运维。
              </b-card-text>
              <b-card-text
                style="text-align: left; line-height: 50px; text-indent: 2em"
              >
                •
                通过电流数据监控，对历史数据进行收集比对，采用机器学习技术建立设备故障预测模型，通过小鲲AIoT平台与边缘智能算法实现对高价值设备、关键零部件的故障诊断、预测性报警及设备的智能化管理，从而降低被动维修或者预防性维修次数。
              </b-card-text>
              <b-card-sub-title style="text-align: left" class="mb-4 mt-4"
                >项目收益</b-card-sub-title
              >
              <b-img
                class="mb-4 mt-4"
                :src="require('../../assets/about/xk_m2.jpg')"
                fluid
              ></b-img> </b-card
          ></b-col>
        </b-row>
      </b-container>
    </div>
    <!-- <Footers /> -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import Footers from "../../components/footers.vue";
export default {
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "设备运维管理系统",
      meta: [
        {
          name: "keywords",
          content: "小鲲智能,小鲲智能技术(广州)有限公司,小鲲智能技术广州有限公司,设备运维管理系统",
        },
        {
          name: "description",
          content:
            "以小鲲自研的物联PLC或网关为前端感知入口，通过小鲲AIoT平台实现设备的远程控制、实时参数读取、设备故障报警及运维人员管理，实现无人化精准运维。",
        },
      ],
    };
  },
  created() {
    scrollTo(0, 0);
  },
  // components: {
  //   Footers,
  // },
  mounted() {
    new WOW({ live: false }).init();
  },
};
</script>
<style lang="less" scoped>
.case {
  .problem_box {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 5px #f0f0f0 inset;
    padding: 40px 320px 20px 320px;
    .text-muted {
      color: #0099ba !important;
    }
  }
  @media screen and (min-width: 375px) and (max-width: 992px) {
    .problem_box {
      padding: 40px 20px 40px 20px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1550px) {
    .problem_box {
      padding: 40px 140px 40px 140px;
    }
  }
  @media screen and (max-width: 375px) {
    .problem_box {
      padding: 40px 0 40px 0;
    }
  }
}
</style>